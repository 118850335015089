import './Graph.css';

interface CircleContainerProps {
  phrases: { phrase: string; tooltip?: string }[];
  onSelect: (phrase: string) => void;
  centeredPhrase?: string;
  selectedIdx?: number;
}

export const CircleContainer = ({
  phrases,
  onSelect,
  centeredPhrase,
  selectedIdx,
}: CircleContainerProps) => {
  const radius = 150;

  const getPointPositionStyle = (x: number, y: number, height: number) => {
    const bulletPointSizePx = 32;
    const bulletPointBasicStyle = {
      left: `${x + height / 2}px`,
      top: `${y + height / 2}px`,
      gap: '5px',
      transform: `translate(-${bulletPointSizePx / 2}px, -50%)`,
    };
    if (y === height / 2) {
      return {
        ...bulletPointBasicStyle,
        'flex-direction': 'column',
        transform: `translate(-50%, calc(${bulletPointSizePx / 4}px - 50%))`,
      };
    }
    if (y === -(height / 2)) {
      return {
        ...bulletPointBasicStyle,
        'flex-direction': 'column-reverse',
        transform: `translate(-50%, calc(-${bulletPointSizePx / 4}px - 50%))`,
      };
    }

    if (x < 0) {
      return {
        ...bulletPointBasicStyle,
        'flex-direction': 'row-reverse',
        transform: `translate(calc(${bulletPointSizePx / 2}px - 100%), -50%)`,
      };
    }
    return {
      ...bulletPointBasicStyle,
    };
  };

  return (
    <div
      className="relative fade-in circle-container"
      style={{ width: radius * 2, height: radius * 2 }}
    >
      <div className="w-[94%] h-[94%] rounded-full bg-white absolute"></div>
      <div className="w-[85%] h-[85%] rounded-full bg-[#81CBC530] absolute"></div>
      {centeredPhrase && (
        <div className="fadein center max-w-[200px] text-center z-50 text-mid">
          {centeredPhrase}
        </div>
      )}
      {phrases.map((phrase: any, index: number) => {
        const x = radius * Math.cos((2 * Math.PI * index) / phrases.length);
        const y = radius * Math.sin((2 * Math.PI * index) / phrases.length);
        const style = getPointPositionStyle(x, y, radius * 2);

        return (
          <div
            key={index}
            className="absolute cursor-pointer phrase"
            style={style}
            onClick={() => {
              onSelect(phrase.phrase);
            }}
          >
            <div
              className={`dot ${index === selectedIdx ? 'filled-dot' : ''}`}
            ></div>
            <span
              title={phrase.tooltip || phrase.phrase}
              className={`w-max text-mid text-black-100 font-body-regular max-w-[250px] truncate ${
                index === selectedIdx ? 'font-bold' : ''
              }`}
            >
              {phrase.phrase}
            </span>
          </div>
        );
      })}
    </div>
  );
};
