const apiUrl = process.env.API_URL;

class FormService {
  async getFormById(id: string) {
    const response = await fetch(`${apiUrl}/form/${id}`, {});
    if (!response.ok) {
      throw response;
    }

    return response.json();
  }

  async saveAnswers(data: any) {
    const response = await fetch(`${apiUrl}/form/save-answers`, {
      method: 'POST',
      body: data,
    });

    if (!response.ok) {
      throw response;
    }

    return response.json();
  }

  async reviewForm(data: any) {
    const response = await fetch(`${apiUrl}/form/review-form`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw response;
    }

    return response.json();
  }

  async downloadFileById(id: string, name: string) {
    const response = await fetch(`${apiUrl}/form/download-file/${id}`, {});
    if (!response.ok) {
      throw response;
    }
    const url = window.URL.createObjectURL(await response.blob());
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
    return response;
  }
}

export const formService = new FormService();
