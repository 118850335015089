import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Objectives } from '../types';
import { ItemContainer } from '../components/ItemContainer';

export const ObjectivesView = () => {
  const { sectionId } = useParams<{ sectionId: string; id: string }>();
  const { data } = useSelector((state: any) => state.data);
  const objective = data.objectives.find(
    (item: Objectives) => item.id.toString() === sectionId
  );

  if (!objective) {
    return null;
  }

  return (
    <>
      <section className="self-stretch flex flex-col items-start justify-start pt-0 pb-[15px] pr-[23px] pl-[22px] box-border gap-4 max-w-full w-auto h-auto text-left text-sm font-body-regular">
        <ItemContainer
          key={objective.id}
          title={objective.id}
          description={objective.description?.replace(/\n/g, '')}
          showInput={false}
        />
      </section>
    </>
  );
};
