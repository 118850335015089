import { Routes, Route, Navigate } from 'react-router-dom';
import NotFound from './pages/NotFound';
import { FormView } from './pages/FormView';
import { SectionView } from './pages/SectionView';
import { HomeView } from './pages/HomeView';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/plan-estrategico" replace />} />
      <Route path="/plan-estrategico" element={<HomeView />} />
      <Route
        path="/plan-estrategico/objetivos-estrategicos"
        element={<HomeView />}
      />
      <Route path="/token/:id" element={<FormView />} />
      <Route path="/token/:id/:section/:sectionId" element={<SectionView />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
export default App;
