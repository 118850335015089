import { useDropzone } from 'react-dropzone';

interface MyDropzoneProps {
  onDrop: (acceptedFiles: File[]) => void;
  selectedFiles: { name: string; id: string; uploaded: boolean }[];
  onRemove?: (idx: number, file: { name: string; id: string; uploaded: boolean }) => void;
  disabled: boolean;
}

const MyDropzone = ({
  onDrop,
  selectedFiles,
  onRemove,
  disabled,
}: MyDropzoneProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled,
  });

  const getDropzZoneText = () => {
    if (disabled) {
      return 'No se pueden subir archivos';
    }

    return isDragActive
      ? 'Suelta los archivos aquí ...'
      : 'Arrastra algunos archivos aquí, o pulsa para seleccionar un archivo';
  };

  return (
    <>
      <div
        {...getRootProps()}
        className="h-[160px] w-[100%] flex flex-row items-center bg-white justify-center border-[1px] border-dashed border-gainsboro rounded-lg cursor-pointer"
      >
        <input {...getInputProps()} />
        {getDropzZoneText()}
      </div>
      {selectedFiles && selectedFiles.length > 0 && (
        <div>
          <ul className='my-1'>
            {selectedFiles.map((file, idx) => (
              <li key={file.name + idx}>
                <div>
                  <span className="mr-[10px]">{file.name}</span>
                  {onRemove && (
                    <img
                      className="cursor-pointer"
                      onClick={() => !disabled && onRemove(idx, file)}
                      src="/images/cross.svg"
                      alt=""
                    />
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default MyDropzone;
