import { useSelector } from "react-redux";
import { ripples } from "ldrs";

export const LoadingComponent = () => {
  const isLoading = useSelector((state: any) => state.loading);

  if (!isLoading) return null;
  ripples.register();

  return (
    <div className="flex justify-center items-center fixed h-screen w-full z-10 bg-gray-100">
      <l-ripples size="100" speed="2" color={'#01A181'}></l-ripples>
    </div>
  );
};
