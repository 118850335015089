interface GenericInfoModalProps {
  title: string;
  description: string;
  toggleModal: (open: boolean) => void;
}

export const GenericInfoModal = ({
  toggleModal,
  title,
  description,
}: GenericInfoModalProps) => {
  const handleClose = () => {
    toggleModal(false);
  };

  return (
    <>
      <div className="flex justify-center items-center fixed h-screen w-full z-8">
        <div className="flex items-center justify-center min-h-screen">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 animate-fade-in-modal opacity-75"></div>
          </div>
          <div className="flex flex-col bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full min-h-[135px] min-w-[385px] max-w-[750px] px-[89px] py-[59px] gap-10 animate-fade-in-down">
            <div className="flex flex-col">
              <div>
                <span
                  className="absolute rounded-full bg-[#E5E5E5] p-1 w-6 h-6 flex justify-center items-center cursor-pointer top-[45px] right-[26px]"
                  onClick={handleClose}
                >
                  ​<img src="/images/close.svg" alt="Close" />
                </span>
                <h4 className="m-0 text-[#54595E] font-body-regular font-semibold leading-7 text-xl">
                  {title}
                </h4>
              </div>
              <p className="font-medium font-body-regular text-sm max-w-[250px] text-[#949494]">
                {description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
