import { useDispatch, useSelector } from 'react-redux';
import { ItemContainer } from '../components/ItemContainer';
import { useParams } from 'react-router-dom';
import { AnswersOds, ODS } from '../types';
import { useState } from 'react';
import { setAnswers } from '../features/dataSlice';
import { toast } from 'sonner';
import { FormStatus, UserRole } from '../enums';

export const OdsView = () => {
  const dispatch = useDispatch();
  const { sectionId } = useParams<{ sectionId: string }>();
  const { data } = useSelector((state: any) => state.data);
  const ods = data.ods.find((item: ODS) => item.id.toString() === sectionId);
  const odsAanswers = data.answers.ods?.find(
    (item: AnswersOds) => item.id.toString() === sectionId
  );

  const [checkedItems, setCheckedItems] = useState<Array<string>>(
    odsAanswers ? odsAanswers?.metas : []
  );

  const isVerifier = () => data?.role === UserRole.Verifier;
  const isEnabledResponsible = () => !isVerifier() && (data?.state === FormStatus.Pending || data?.state === FormStatus.Rejected);

  if (!ods) {
    return null;
  }

  const handleCheckedChange = async (meta: string, isChecked: boolean) => {
    setCheckedItems((prevState) => {
      const newCheckedItems = [...prevState];

      if (isChecked) {
        newCheckedItems.push(meta);
      } else {
        const index = newCheckedItems.findIndex((item) => item === meta);
        if (index > -1) {
          newCheckedItems.splice(index, 1);
        }
      }

      const answers = JSON.parse(JSON.stringify(data.answers));
      const index = answers.ods.findIndex(
        (item: AnswersOds) => item.id.toString() === sectionId
      );
      if (index > -1) {
        answers.ods[index] = {
          ...answers.ods[index],
          metas: newCheckedItems,
          id: ods.id,
        };
      } else {
        answers.ods.push({ metas: newCheckedItems, id: ods.id });
      }

      try {
        dispatch(setAnswers(answers));
        toast.success("Los cambios se han guardado con éxito!");
      } catch (error) {
        toast.error("Hubo un error al guardar los cambios.");
      }

      return newCheckedItems;
    });
  };

  const renderItemContainer = (
    ods: any,
    index: number,
    checkedItems: Array<string>,
    handleCheckedChange: (meta: string, isChecked: boolean) => void
  ) => {
    let metaKey: string;
    let title: string;
    switch (index) {
      case 20:
        metaKey = 'metaA';
        title = `Meta ${ods.id}.A`;
        break;
      case 21:
        metaKey = 'metaB';
        title = `Meta ${ods.id}.B`;
        break;
      case 22:
        metaKey = 'metaC';
        title = `Meta ${ods.id}.C`;
        break;
      default:
        metaKey = `meta${index + 1}`;
        title = `Meta ${ods.id}.${index + 1}`;
    }
    if (ods[metaKey]?.replace(/\n/g, '')) {
      return (
        <ItemContainer
          key={`${ods.id}-${index}`}
          title={title}
          description={ods[metaKey]?.replace(/\n/g, '')}
          onCheckedChange={(isChecked: boolean) =>
            handleCheckedChange(metaKey, isChecked)
          }
          checked={
            checkedItems && metaKey
              ? checkedItems?.some((item) => item === metaKey)
              : false
          }
          inputEnabled={isEnabledResponsible()}
        />
      );
    }
  };

  return (
    <>
      <section className="self-stretch flex flex-col items-start justify-start pt-0 pb-[15px] pr-[23px] pl-[22px] box-border gap-4 max-w-full w-auto h-auto text-left text-sm font-body-regular">
        {Array.from({ length: 23 }, (_, index) =>
          renderItemContainer(ods, index, checkedItems, handleCheckedChange)
        )}
      </section>
    </>
  );
};
