import { CardType } from '../enums';

class UtilsService {
  formatId(type: string, id: string) {
    if (type === CardType.AUDIT && id.includes('.')) {
      return `${id.charAt(0)} (${id})`;
    }
    return id;
  }

  orderArrayByField(array: any[], field: string, ascending: boolean = true) {
    return array.sort((a, b) => {
      const parsedA = parseInt(a[field], 10);
      const parsedB = parseInt(b[field], 10);
      if (!isNaN(parsedA) && !isNaN(parsedB)) {
        return parsedA - parsedB;
      }
      if (a[field] < b[field]) {
        return ascending ? -1 : 1;
      }
      if (a[field] > b[field]) {
        return ascending ? 1 : -1;
      }
      return 0;
    });
  }

  formatDate(date: string) {
    if (!date) return '';
    const months = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ];
    const dateArray = date.split(' ');
    const month = months.indexOf(dateArray[0].toLowerCase()) + 1;
    const day = dateArray[1].replace(',', '');
    const year = dateArray[2];
    return `${month}/${day}/${year}`;
  }
}

export const utilsService = new UtilsService();
