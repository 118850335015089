import { FunctionComponent } from 'react';
import { CardType } from '../enums';
import { Link } from 'react-router-dom';
import { ODS, Objectives, Audit } from '../types';
import { utilsService } from '../services/utils.service';

interface CardProps {
  formId: string;
  title: string;
  items: ODS[] | Objectives[] | Audit[];
  type: CardType;
  handleAddNote?: () => void;
  handleViewNote?: () => void;
}

const CardContainer: FunctionComponent<CardProps> = ({
  formId,
  title,
  items,
  type,
  handleAddNote,
  handleViewNote,
}) => {
  const formatId = (id: string) => {
    return utilsService.formatId(type, id);
  };

  const getSectionImg = () => {
    if (type === CardType.ODS) {
      return (
        <img
          className="w-[68px] h-[67px] object-cover"
          loading="eager"
          src={`/images/sdg_wheel.png`}
          alt=""
        />
      );
    }
    if (type === CardType.AUDIT) {
      return (
        <img
          className="w-[97px] h-[45px] object-contain"
          loading="eager"
          src="/images/aneca_logo.png"
          alt=""
        />
      );
    }
    if (type === CardType.OBJECTIVES) {
      return (
        <img
          className="w-[75px] h-[59px] object-contain"
          loading="eager"
          src="/images/isotipo_aniversario.png"
          alt=""
        />
      );
    }
  };

  return (
    <div className=" relative flex-[0.9536] rounded-lg bg-white text-center shadow-[0px_2px_8px_rgba(0,_0,_0,_0.08)] overflow-hidden flex flex-col items-center justify-start py-8 px-[31px] box-border gap-6 min-w-[324px] max-w-full h-auto mq450:flex-1">
      <div className="flex flex-col items-center justify-center gap-[5px] w-[100%]">
        {handleAddNote && (
          <button
            onClick={handleAddNote}
            className="bg-white border-none text-primary-green ml-auto cursor-pointer text-smi font-semibold font-body-regular"
          >
            + Añadir nota
          </button>
        )}
        {handleViewNote && (
          <button
            onClick={handleViewNote}
            className="bg-white border-none text-primary-green ml-auto cursor-pointer text-smi font-semibold font-body-regular absolute left-[10px] top-[10px]"
          >
            <img
              className="w-[30px] h-[30px]"
              src="/images/warning.svg"
              alt=""
            />
          </button>
        )}
        <div className="tracking-[0.01em] font-semibold text-transparent !bg-clip-text [background:linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_#323232] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] flex items-center justify-between w-[100%]">
          {title}
          {getSectionImg()}
        </div>
      </div>
      <div className="self-stretch overflow-hidden flex flex-row items-center justify-start py-0 pr-0 pl-px box-border max-w-full w-auto h-auto gap-0">
        <div className="flex-1 flex flex-col items-start justify-start py-0 px-0 box-border max-w-full h-auto gap-0 [transform:rotate(0deg)]">
          <div className="w-full flex flex-row items-start flex-wrap justify-start pt-2.5 pb-2.5 box-border gap-8 cursor-pointer max-w-full h-auto mq450:gap-4">
            {items.map((item) => (
              <Link
                className="no-underline text-[#4B4B4B]"
                key={item.id}
                to={`/token/${formId}/${type.toLowerCase()}/${item.id}`}
              >
                {type === CardType.ODS ? (
                  <div className="rounded-lg shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-center pt-[13px] px-3 pb-3 cursor-pointer border-[0.4px] border-solid border-transparent">
                    <img
                      className="w-[68px] h-[67px] object-cover"
                      loading="eager"
                      alt={(item as ODS).ods}
                      src={`/images/odsicon${item.id}.png`}
                    />
                  </div>
                ) : (
                  <div
                    className={`rounded-lg shadow-[0px_2px_5px_rgba(0,_0,_0,_0.25)] box-border w-[92px] shrink-0 flex flex-row items-center justify-center py-9 px-0 border-[0.4px] border-solid border-transparent ${
                      type === CardType.AUDIT ? 'bg-bg-purple text-white' : ''
                    }`}
                  >
                    <div className="flex-1 relative tracking-[0.2px] leading-[22px] font-medium">
                      {formatId(item.id)}
                    </div>
                  </div>
                )}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardContainer;
