interface data {
  message: string;
  children: React.ReactNode;
}

export default function Tooltip({ message, children }: data) {
  return (
    <div className="group relative flex">
      <span className="absolute top-[-35px] scale-0 transition-all rounded bg-primary-green py-[5px] px-[10px] text-xs text-white group-hover:scale-100">
        {message}
      </span>
      {children}
    </div>
  );
}
