import { useSelector } from 'react-redux';
import { SectionType, UserRole } from '../enums';
import { TitleSection } from './TitleSection';
import StatusCircle from './StatusCircle';
interface HeaderProps {
  section: SectionType | string;
  infoAction?: () => void;
}

export const Header = ({ section, infoAction }: HeaderProps) => {
  const form = useSelector((state: any) => state.data.data);

  return (
    <section className="self-stretch flex flex-row items-start justify-start py-0 px-5 box-border max-w-full w-auto h-auto gap-0 text-center text-15xl font-body-regular">
      <div className="w-full flex flex-col items-start justify-start gap-[30px] max-w-full h-auto">
        <div className="flex flex-row justify-between w-[100%] items-center">
          <img
            className="w-[187px] h-[141px] object-contain"
            loading="eager"
            alt=""
            src="/images/ufpc_aniversario.png"
          />

          <img
            className="w-[105px] h-[92px] object-contain"
            loading="eager"
            alt=""
            src="/images/si_10_aniversario.png"
          />
        </div>
        {section === SectionType.HOME ? (
          <div className="self-stretch flex flex-row items-start justify-end max-w-full w-auto h-auto gap-0">
            <div className="w-full flex flex-col items-center justify-start gap-4 max-w-full h-auto">
              <div className="w-full flex flex-row items-center justify-center gap-[10px] max-w-full h-auto">
                <h1 className="m-0 text-inherit leading-[130%] font-bold font-inherit text-transparent !bg-clip-text [background:linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_#323232] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] flex max-w-full hover:font-bold  text-transparent !bg-clip-text">
                  Activo: {form.asset}
                </h1>
                {infoAction && (
                  <button
                    onClick={() => {
                      infoAction();
                    }}
                    className="bg-gray-100 cursor-pointer p-0 h-[23px] mt-[5px]"
                  >
                    <img src="/images/info.svg" alt="" />
                  </button>
                )}
                <div className="flex mt-[3px]">
                  <StatusCircle status={form.state}></StatusCircle>
                </div>
              </div>
              <div className="self-stretch relative text-base leading-[24px] text-transparent !bg-clip-text [background:linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_#323232] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] whitespace-pre-wrap flex flex-col gap-[20px]">
                <p className="m-0">
                  <span className="font-medium text-xl font-body-regular ml-2">
                    PQN: Proceso Nuclear Calidad: Flujo Validación Activos
                  </span>
                </p>
                <p className="m-0">
                  Descripción: justificar la integración de los elementos del
                  siguiente flujo acompañando una evaluación cuanti-cualitativa
                  y, en su caso, las observaciones sobre cada elemento
                </p>
              </div>
            </div>
          </div>
        ) : (
          <TitleSection />
        )}
      </div>
    </section>
  );
};

export default Header;
