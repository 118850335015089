import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SectionType } from '../enums';
import { useEffect, useState } from 'react';
import {
  ODS_TEXT,
  AUDIT_TEXT,
  OBJECTIVES_TEXT,
  SUBTITLE_ODS_TEXT,
  SUBTITLE_OBJECTIVES_TEXT,
  SUBTITLE_AUDIT_TEXT,
} from '../constants';
import { utilsService } from '../services/utils.service';
import UrlButton from './UrlButton';

export const TitleSection = () => {
  const { id, section, sectionId } = useParams();
  const [title, setTitle] = useState<string>('');
  const [subtitle, setSubtitle] = useState<string>('');
  const data = useSelector((state: any) => state.data.data);
  const navigate = useNavigate();

  const formatId = (id: string) => {
    return utilsService.formatId(section?.toUpperCase() as string, id);
  };

  const getMainAuditSectionInfo = () => {
    const audit = data.audits.find(
      (audit: any) => audit.id.toString() === sectionId?.toString()
    );
    return audit;
  };
  const getMainObjectiveSectionInfo = () => {
    const objective = data.objectives.find(
      (objective: any) => objective.id.toString() === sectionId?.toString()
    );
    return objective;
  };
  const getMainOdsSectionInfo = () => {
    const odsFinal = data.ods.find(
      (ods: any) => ods.id.toString() === sectionId?.toString()
    );
    return odsFinal;
  };

  useEffect(() => {
    if (section !== SectionType.HOME) {
      console.log(data, section, sectionId);
      const item = data[`${section}`]?.find(
        (item: any) => item.id.toString() === sectionId?.toString()
      );
      let title = '';
      let subtitle = '';
      switch (section) {
        case SectionType.ODS:
          title = `${ODS_TEXT}${sectionId}: ${item.ods}`;
          subtitle = getMainOdsSectionInfo()?.objective || '';
          break;
        case SectionType.AUDIT:
          title = `${AUDIT_TEXT}${formatId(item.id)}. ${
            getMainAuditSectionInfo()?.criteria || ''
          }`;
          subtitle = getMainAuditSectionInfo()?.description || '';
          break;
        case SectionType.OBJECTIVES:
          title = `${OBJECTIVES_TEXT}${item.id} (${
            getMainObjectiveSectionInfo()?.objective
          })`;
          subtitle = '';
          break;
        default:
          title = '';
          subtitle = '';
      }
      setTitle(title);
      setSubtitle(subtitle);
    }
  }, [section, sectionId, data]);

  const handleOnClick = () => {
    navigate(`/token/${id}`);
  };

  const getTitleExtraElement = () => {
    if (section === SectionType.ODS && getMainOdsSectionInfo().infoUrl) {
      return (
        <div>
          <UrlButton url={getMainOdsSectionInfo().infoUrl}></UrlButton>
        </div>
      );
    }
    if (
      section === SectionType.OBJECTIVES &&
      getMainObjectiveSectionInfo().infoUrl
    ) {
      return (
        <div>
          <UrlButton url={getMainObjectiveSectionInfo().infoUrl}></UrlButton>
        </div>
      );
    }
  };

  return (
    <section className="self-stretch flex flex-col items-start justify-start py-0 box-border gap-4 min-h-[92] max-w-full w-auto h-auto [transform:rotate(0deg)] text-left text-15xl font-body-regular">
      <div className="flex flex-row items-center justify-start gap-4 max-w-full w-auto [align-self:unset] h-auto">
        <img
          className="w-[9.3px] h-[16.2px] cursor-pointer"
          loading="eager"
          alt=""
          src="/images/vector.svg"
          onClick={handleOnClick}
        />
        <h1 className="m-0 text-inherit leading-[130%] font-bold font-inherit text-transparent !bg-clip-text [background:linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_#323232] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-xl mq450:leading-[27px] mq750:text-8xl mq750:leading-[35px]">
          {title}
        </h1>
        {getTitleExtraElement()}
      </div>
      {subtitle && (
        <div className="self-stretch text-sm leading-[24px] font-medium text-transparent !bg-clip-text [background:linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_#323232] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
          {subtitle}
        </div>
      )}
    </section>
  );
};
